import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'

import theme from '../../utils/theme'
// Initialize the client with the oauth token

var axios = require('axios')
var formData = require('form-data')

const Profile = () => {
    const [fileSelected, setFileSelected] = React.useState();

    const onFileChange = (e) => {
        let file = e.target.files[0]
        let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if (file) {
            if (!allowedExtensions.exec(file.name)) {
                alert("Only image file allowed!");
                e.target.value = "";
                setFileSelected();
                return false;
            }
            console.log(file)
            setFileSelected(file)
        }
    }

    const fileUpload = (signed_request, success, failed) => {
        let fd = new FormData()
        for (var key in signed_request.fields) {
            fd.append(key, signed_request.fields[key])
        }
        fd.append("file", fileSelected)
        axios.post(signed_request.post_url, fd)
            .then(resp =>
                console.log('https://a.storyblok.com/' + signed_request.fields.key + ' UPLOADED!')
            )
    }

    var signAsset = function (access_token) {
        axios.post('https://api.storyblok.com/v1/spaces/' + process.env.GATSBY_SPACE_ID + '/assets', {
            // add the id to update an existing asset. 'id: 123,'
            filename: fileSelected.name,
            size: '400x500',
            asset_folder_id: null
        }, {
            headers: { 'Authorization': access_token }
        })
            .then(function (response) {
                fileUpload(response.data, function () {
                    console.log('Done')
                }, function () {
                    console.log('Failed')
                })
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    return (
        <ThemeProvider theme={theme}>
            < Layout >
                <input type="file" onChange={onFileChange} accept="image/*" /><br />
                <button onClick={() => signAsset(process.env.GATSBY_ACCESS_TOKEN)}>Upload</button><br />
                {fileSelected && <img src={URL.createObjectURL(fileSelected)} />}<br />
                <br />
                <Link to="/">Go back to the homepage</Link>
            </Layout >
        </ThemeProvider>
    )
}

export default Profile
